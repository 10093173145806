<template>
  <div>
    <section class="section section-lg bg-web-desc">
      <div class="bg-overlay"></div>
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center margin-t-70">
            <h2 class="text-white">Mülakat Simülasyonu Nedir?</h2>
          </div>
        </div>
      </div>
      <div class="bg-pattern-effect">
        <img src="@/assets/images/bg-pattern-light.png" alt />
      </div>
    </section>

    <section class="section bg-light margin-t-70" id="features">
      <div class="container">
        <div class="row vertical-content">
          <div class="col-lg-5">
            <div class="features-box">
              <h3>Mülakat Simülasyonu</h3>
              <p class="text-dark web-desc">
                Mülakatlara her zaman önyargıyla yaklaşıyoruz. Mülakatın, bizi
                değerlendiren İnsan Kaynakları Profesyoneline kendimizi tüm
                yönlerimizle açtığımız ve ona mevcut işi ne kadar da iyi
                yapabileceğimizi gösterme toplantısı olduğunu düşünüyoruz. Bu
                düşüncede doğruluk payı da olsa, iş arayan taraf için oldukça
                gergin bir başlangıcın nedeni.
              </p>
              <p class="text-dark web-desc">
                Öncelikle mülakatın bu soğuk tarafını yenmenin önemini
                konuşacağız, mülakatın asıl amacını tartışacağız, ardından
                sektörünüze ve mesleğinize uygun spesifik simülasyonlarla
                heyecanınızı atmanıza yardımcı olacağız.
              </p>
              <p class="text-dark web-desc">
                Aşağıda yer alan iletişim formunu doldurup hizmetimiz hakkında daha fazla detay alabilirsiniz.
              </p>              
            </div>
          </div>
          <div class="col-lg-7">
            <div class="features-img features-right text-right">
              <img
                src="@/assets/images/whatwedo/online_interview3.svg"
                alt="macbook image"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

