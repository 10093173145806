

<script>
import Navbar from "@/components/navbar-stable";
import Footer from "@/components/footer";
import ServiceDetail from "@/components/interview-simulation-detail"
import Contact from "@/components/contact";

/**
 * index-9 component
 */
export default {
  name: "interview-simulation",
  components: {
    Navbar,
    Footer,
    ServiceDetail,
    Contact
  },
};
</script>

<template>
  <div>
    <Navbar/>
    <ServiceDetail/>
    <Contact/>
    <Footer/>
  </div>
</template>