<script>
export default {
  mounted: () => {
    var navbar = document.getElementById("navbar");
    navbar.style.backgroundColor = "#272a33";
    navbar.style.padding = "10px";
  },
  methods: {
    toggleMenu() {
      document.getElementById("navbarCollapse").classList.toggle("show");
    },
  },
};
</script>

<template>
  <!--Navbar Start-->
  <nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" id="navbar">
    <div class="container">
      <!-- LOGO -->
      <a class="navbar-brand logo text-uppercase" href="/">
        <img src="@/assets/images/logo.png" class="logo-default" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click="toggleMenu()"
      >
        <i class="mdi mdi-menu"></i>
      </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <ul class="navbar-nav ml-auto navbar-center" id="mySidenav" v-scroll-spy-active>
        <li class="nav-item">
          <a href="/" class="nav-link">Anasayfa</a>
        </li>
        <li class="nav-item">
          <a href="/ne-yapiyoruz" class="nav-link">Ne Yapıyoruz?</a>
        </li>
        <li class="nav-item">
          <a href="/mulakat-simulasyonu" class="nav-link">Mülakat Simülasyonu</a>
        <li class="nav-item">
          <a href="/cozum-ortaklarimiz" class="nav-link">Çözüm Ortaklarımız</a>
        </li>
        <li class="nav-item">
          <a href="/iletisim" class="nav-link">İletişim</a>
        </li>
    </ul>  
  </div>
    </div>
  </nav>
  <!-- Navbar End -->
</template>